<template>
  <section v-if="playlist !== null">
    <Navbar />

    <div
      class="px-4 lg:px-2 py-8 2xl:py-10 md:px-12 flex-1 2xl:container 2xl:mx-auto"
    >
      <div
        class="flex lg:flex-row flex-col-reverse space-y-4 space-y-reverse justify-between items-start"
      >
        <div class="lg:flex lg:space-x-6 mb-10">
          <img
            :src="playlist.image"
            :alt="playlist.name"
            class="w-24 h-24 lg:w-36 lg:h-36 object-cover mb-5 md:w-52 md:h-52 2xl:w-72 2xl:h-72 rounded"
          />

          <div class="flex-auto">
            <h1 class="text-2xl 2xl:text-4xl font-semibold mb-2">
              {{ playlist.name }}
            </h1>

            <p class="text-white opacity-60 mb-4">
              {{ playlist.description }}
            </p>

            <ul class="text-sm text-white opacity-60 mb-4">
              <li>{{ songs.length }} brani</li>
              <li>Spotify ID: {{ playlist.spotifyId }}</li>
              <li>Apple Music ID: {{ playlist.appleMusicId }}</li>
            </ul>
          </div>

          <div>
            <label
              for="playlist-image2"
              class="leading-none text-sm font-semibold"
              >Immagine secondaria:</label
            >
            <input
              name="playlist-image2"
              id="playlist-image2"
              class="border-none bg-slate-800 rounded w-full focus:ring-2 focus:ring-sky-500 outline-none"
              type="text"
              v-model="playlist.image2"
            />
            <button class="btn btn--red ml-auto" @click="updateImage">Salva</button>
          </div>
        </div>

        <button class="btn btn--red ml-auto">
          <router-link to="/playlists">&larr; Torna alle playlist</router-link>
        </button>
      </div>
      <div
        v-if="successMsg !== null"
        :class="{ 'bg-green-100 border-green-200 text-green-500': successMsg }"
        class="mt-2 p-1 border rounded-sm text-xs"
      >
        {{ successMsg }}
      </div>
      <div
        class="hidden lg:flex w-full space-x-4 text-xs 2xl:text-sm uppercase tracking-wider opacity-50 pb-3 px-3 leading-none border-b border-white border-opacity-10 mb-3"
      >
        <div class="hidden lg:block w-8 text-center">#</div>
        <div class="w-6/12">Titolo</div>
        <div class="w-5/12">Album</div>
      </div>

      <div class="flex flex-col gap-4">
        <CardSong
          v-for="(song, index) in songs"
          :key="song.id"
          :id="song.id"
          :name="song.name"
          :album="song.album"
          :artist="song.artist"
          :image="song.image"
          :index="index"
        />
      </div>
    </div>

    <Footer />
  </section>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import CardSong from "@/components/CardSong.vue";
import { getClient } from "../includes/api";

export default {
  components: { Footer, Navbar, CardSong },
  props: ["id"],
  data() {
    return {
      playlist: null,
      songs: [],
      i: 1,
      successMsg: null,
    };
  },
  async mounted() {
    try {
      const apiClient = getClient();
      this.playlist = await apiClient.getPlaylist(this.id);
      let songs = await apiClient.getSongs(this.id);
      if (Array.isArray(songs)) {
        this.songs = songs;
      }
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    async updateImage() {
      const client = getClient();
      this.playlist = await client.updatePlaylist(this.id, {
        image2: this.playlist.image2,
      });
      this.success("Playlist saved");
    },
    success(str) {
      this.successMsg = str;
      setTimeout(() => {
        this.successMsg = null;
      }, 3000);
    },
  },
};
</script>
