<template>
  <article class="rounded lg:flex lg:space-x-2 lg:gap-4 items-center">
    <span class="hidden lg:block w-8 text-center">{{ index + 1 }}</span>
    <div class="lg:w-6/12 flex lg:space-x-2">
      <img
        class="hidden lg:block w-12 h-12 object-cover rounded-sm"
        :src="image"
        :alt="name"
      />
      <div>
        <h3 class="font-semibold">{{ name }}</h3>
        <p class="text-sm opacity-60">{{ artist }}</p>
      </div>
    </div>
    <p class="lg:w-5/12 2xl:block opacity-60">{{ album }}</p>
  </article>
</template>

<script>
export default {
  props: ["id", "name", "album", "artist", "image", "spotifyId", "index"],
};
</script>

<style></style>
